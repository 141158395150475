import Vue from 'vue'
import Axios from 'axios'

const BASE = '/api'

const api = Axios.create({
  baseURL: `${BASE}/`,

  validateStatus (status) {
    return true
  }
})

api.sync = function (method, url, body = null, onload = null, onerror = null) {
  const xhr = new XMLHttpRequest()
  xhr.open(method, `${BASE}${url}`, false)

  if (onload) {
    xhr.onload = onload
  }
  if (onerror) {
    xhr.onerror = onerror
  }

  if (body === null) {
    xhr.send()
  } else if (typeof body === 'string') {
    xhr.send(body)
  } else {
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(JSON.stringify(body))
  }
}

api.errorReport = function (e) {
  this.post('/errors', {
    url: location.href,
    err: {
      message: e.toString(),
      stack: e.stack ? e.stack.toString() : ''
    }
  })
}

export default api

//
// Vue injections
//
const Injections = {
  install (Vue, options) {
    Object.defineProperties(Vue.prototype, {
      $api: { value: api }
    })
  }
}

Vue.use(Injections)
