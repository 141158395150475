<template>
  <div class="inline-block">
    <icon-star
      v-for="n in max"
      :key="n"
      width="16" height="16"
      class="inline-block fill-current stroke-1"
      :class="n <= value ? 'text-orange-500' : 'text-gray-400'"
    />
  </div>
</template>

<script>
import IconStar from 'zondicons/star-full.svg'

export default {
  name: 'StartRating',
  components: {
    IconStar
  },
  props: {
    value: {
      type: [String, Number],
      default: 1
    },
    max: {
      type: [String, Number],
      default: 3
    }
  }
}
</script>
