/*
 * 日時のフォーマット
 * PHP date() のフォーマット指定を日本語拡張したもの
 */
const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const DayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const AmPm = [
  'AM',
  'PM'
]

const eraJp = [
  { ymd: 20190501, year: 2019, name: '令和', letter: 'R' },
  { ymd: 19890108, year: 1989, name: '平成', letter: 'H' },
  { ymd: 19261225, year: 1926, name: '昭和', letter: 'S' },
  { ymd: 19120730, year: 1912, name: '大正', letter: 'T' },
  { ymd: 18681023, year: 1868, name: '明治', letter: 'M' }
]

const DayNameJp = [
  '日',
  '月',
  '火',
  '水',
  '木',
  '金',
  '土'
]

const AmPmJp = [
  '午前',
  '午後'
]

function z4 (n) {
  return ('0000' + n).slice(-4)
}

function z3 (n) {
  return ('000' + n).slice(-3)
}

function z2 (n) {
  return ('00' + n).slice(-2)
}

function h12 (n) {
  n %= 12
  return n === 0 ? 12 : n
}

// 和暦
function eraJpName (d) {
  const ymd = d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate()
  const e = eraJp.find(e => e.ymd <= ymd)
  return e ? e.name : ''
}

function eraJpLetter (d) {
  const ymd = d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate()
  const e = eraJp.find(e => e.ymd <= ymd)
  return e ? e.letter : ''
}

function eraJpYear (d) {
  const y = d.getFullYear()
  const ymd = y * 10000 + (d.getMonth() + 1) * 100 + d.getDate()
  const e = eraJp.find(e => e.ymd <= ymd)
  return e ? (y - e.year + 1) : y
}

const fnFormats = {
  // 年
  'Y': (d) => z4(d.getFullYear()),
  'y': (d) => z4(d.getFullYear()).slice(-2),
  'J': eraJpName,
  'b': eraJpLetter,
  'k': (d) => '' + eraJpYear(d),
  'K': (d) => {
    const y = eraJpYear(d)
    return y === 1 ? '元' : ('' + y)
  },

  // 月
  'n': (d) => '' + (d.getMonth() + 1),
  'm': (d) => z2(d.getMonth() + 1),
  'F': (d) => MonthNames[d.getMonth()],
  'M': (d) => MonthNames[d.getMonth()].slice(0, 3),

  // 日
  'j': (d) => '' + d.getDate(),
  'd': (d) => z2(d.getDate()),

  // 曜日
  'D': (d) => DayNames[d.getDay()].slice(0, 3),
  'l': (d) => DayNames[d.getDay()],
  'x': (d) => DayNameJp[d.getDay()],

  // AM/PM
  'a': (d) => AmPm[0 | (d.getHours() >= 12)].toLowerCase(),
  'A': (d) => AmPm[0 | (d.getHours() >= 12)],
  'E': (d) => AmPmJp[(0 | d.getHours() >= 12)],
  'e': (d) => {
    const h = d.getHours()
    return AmPmJp[0 | (h === 0 || h >= 13)]
  },

  // 時
  'G': (d) => '' + d.getHours(),
  'H': (d) => z2(d.getHours()),
  'g': (d) => '' + h12(d.getHours()),
  'h': (d) => z2(h12(d.getHours())),
  'p': (d) => '' + (d.getHours() % 12),
  'q': (d) => z2(d.getHours() % 12),

  // 分
  'i': (d) => z2(d.getMinutes()),

  // 秒
  's': (d) => z2(d.getSeconds()),

  // ミリ秒
  'v': (d) => z3(d.getMilliseconds())
}

const reFormat = /[YyJbKknmFMjdDixaAEeGHghpqisv]/g

export function format (fmt, d) {
  const date = (d instanceof Date) ? d : new Date(d)

  function extract (word) {
    return fnFormats[word](date)
  }

  return fmt.replace(reFormat, extract)
}

export function clone (dt) {
  return new Date(dt.valueOf())
}

export function today () {
  const tm = new Date()
  tm.setHours(0, 0, 0, 0)
  return tm
}

export function intdate (s) {
  let y, m, d

  if (!s) {
    return null
  }

  s = '' + s
  if (s.length === 4) {
    y = s - 0
    m = 1
    d = 1
  } else if (s.length === 6) {
    y = s.slice(0, 4) - 0
    m = s.slice(4, 6) - 0
    d = 1
  } else if (s.length === 8) {
    y = s.slice(0, 4) - 0
    m = s.slice(4, 6) - 0
    d = s.slice(6, 8) - 0
  } else {
    return null
  }
  return new Date(y, m - 1, d)
}

export function wait (milliseconds) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), milliseconds)
  })
}
