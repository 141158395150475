<template>
  <header
    class="flex items-center px-4 py-2 bg-gray-400"
  >
    <h1 class="font-bold tracking-wide">{{title}}</h1>

    <div class="flex flex-1 mx-4">
      <slot name="left"/>
      <div class="flex-1"/>
      <slot name="right"/>
    </div>

    <div>
      <span>【{{rolename}}】</span>
      <span>{{username}}</span>
      <small class="ml-1 text-xs">v2021-03-13 21:18</small>
      <button
        type="button"
        class="button button-urbane button-blue ml-4"
        @click="$emit('logout')"
      >ログアウト</button>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    title: String
  },
  computed: {
    ...mapState({
      username: state => state.user.name,
      rolename: state => state.user.rolename,
      areaname: state => state.user.areaname
    })
  }
}
</script>
