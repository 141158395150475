<template>
  <div class="select-outer">
    <select
      class="select"
      v-bind="$attrs"
      :value="value"
      @input="$emit('change', $event.target.value)"
      :disabled="options.length == 0 || disabled"
    >
      <option v-if="none !== null" value="">{{none}}</option>
      <option
        v-for="item in options"
        :key="item.code"
        :value="item.code"
        :selected="item.code == value"
      >{{item.name}}</option>
    </select>
    <div class="select-arrow">
      <cheveron-down class="fill-current h-4 w-4"/>
    </div>
  </div>
</template>

<script>
import CheveronDown from 'zondicons/cheveron-down.svg'

export default {
  components: {
    CheveronDown
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    none: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>
