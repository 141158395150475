//
// ページの状態管理
//
export default {
  state: {
    currentPage: null,
    ready: false,
    errors: []
  },

  mutations: {
    stateInit (state, page) {
      state.currentPage = page
      state.ready = false
      state.errors = []
    },

    stateReady (state) {
      state.ready = true
    },

    setPageErrors (state, errors) {
      state.errors = errors || []
    }
  }
}
