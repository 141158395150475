//
// PartBase
//
import { mapGetters, mapMutations } from 'vuex'
import { HTTP } from '@/lib/code'
import Debounce from './Debounce'

export default {
  computed: {
    ...mapGetters([
      'userID',
      'userRole',
      'userArea'
    ])
  },
  methods: {
    ...mapMutations([
      'stateInit',
      'stateReady',
      'setPageErrors'
    ]),

    //
    // エラー関連
    //
    errorNotFound () {
      this.setPageErrors([
        `指定のデータが見つかりませんでした。`,
        'システム管理者にお問合せ下さい。'
      ])
    },

    errorApiFailed (status) {
      this.setPageErrors([
        `サーバとの通信に失敗しました。ERROR: ${status}`,
        'システム管理者にお問合せ下さい。'
      ])
    },

    errorReport (err) {
      console.error(err)

      // サーバへエラーを報告する
      this.$api.errorReport(err)
    },

    //
    // 通信リクエストを実行する
    //
    async request (run) {
      try {
        // 通信処理を実行する
        let result = await run(this.$api)

        // resp が返されたらエラーとみなす
        if (typeof result === 'object' && result.status && result.config) {
          this.errorApiFailed(result.status)
          return null
        }

        // それ以外は値として返す
        return result
      } catch (err) {
        this.errorApiFailed(HTTP.InternalSystemError)
        this.errorReport(err)
      }
    },

    //
    // マスタを取得する
    //
    loadMaster (params, onLoaded) {
      return this.request(async api => {
        const resp = await api.get('/masters', { params })

        if (resp.status !== HTTP.OK) {
          return resp
        }

        onLoaded(resp.data)

        return true
      })
    },

    //
    // マスタ配列から対応する名前を返す
    //
    masterName (m, code) {
      const target = m.find(item => item.code === code)
      return target ? target.name : ''
    },

    //
    // イベントを間引く
    //
    debounce (handler, msec) {
      return new Debounce(handler, msec)
    }
  }
}
