<template>
  <div id="app">
    <app-header :title="title" @logout="onLogout">
      <template #left>
        <slot name="left"/>
      </template>

      <template #right>
        <slot name="right"/>
      </template>
    </app-header>

    <main class="main" :class="{hidden: !ready}">
      <slot/>
    </main>

    <app-error :errors="errors"/>
  </div>
</template>

<script>
import AppHeader from './AppHeader'
import AppError from './AppError'

import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    AppHeader,
    AppError
  },
  props: {
    title: String
  },
  computed: {
    ...mapState({
      ready: state => state.pagestate.ready,
      errors: state => state.pagestate.errors
    }),
    ...mapGetters([
      'userID'
    ])
  },
  methods: {
    onLogout () {
      if (this.$listeners['logout']) {
        this.$emit('logout')
      } else {
        this.logout()
      }
    },
    async logout () {
      await this.$api.delete(`/users/${this.userID}/ref`)
      location.href = '/'
    }
  }
}
</script>
