//
// ログインユーザ情報
//
export default {
  state: {
    userid: 0,
    name: '',
    areacode: '',
    areaname: '',
    rolecode: '',
    rolename: ''
  },

  getters: {
    userID (state) {
      return state.userid
    },
    userRole (state) {
      return state.rolecode
    },
    userArea (state) {
      return state.areacode
    }
  },

  mutations: {
    userFromStorage (state) {
      const u = sessionStorage.getItem('ccms:user')
      if (!u) {
        return
      }

      const data = JSON.parse(u)

      state.userid = data.userid
      state.name = data.name
      state.areacode = data.areacode
      state.areaname = data.areaname
      state.rolecode = data.rolecode
      state.rolename = data.rolename
    }
  }
}
