//
// PageBase
//
import PartBase from './PartBase'

import AppPage from '@/cm/components/AppPage'

export default function PageBase (title) {
  return {
    mixins: [
      PartBase
    ],
    components: {
      AppPage
    },
    metaInfo: {
      title: title
    },
    data () {
      return {
        pageTitle: title
      }
    },
    created () {
      this.stateInit(this)
      this.enterPage()
    },
    beforeDestroy () {
      this.leavePage()
    },
    methods: {
      enterPage () {
      },
      leavePage () {
      },
      async logout () {
        // ユーザ参照カウントを -1 する
        await this.decUserRef()
        location.href = '/'
      },
      decUserRef () {
        // ユーザ参照カウントを -1 する
        return this.$api.delete(`/users/${this.userID}/ref`)
      }
    }
  }
}
