<template>
  <div class="input-start-rating inline-block relative">
    <label
      v-for="val in max"
      :key="val"
      :class="classes(val)"
      :style="{'z-index': (max - val + 1)}"
    >
      <input type="radio" class="stealth" name="star" :value="val" @input="onChange(val)"/>
      <icon-star
        v-for="n in val"
        :key="n"
        width="16" height="16"
        class="inline-block fill-current stroke-1"
        :class="val <= value ? 'text-orange-500' : 'text-gray-400'"
      />
    </label>
  </div>
</template>

<script>
import IconStar from 'zondicons/star-full.svg'

export default {
  name: 'InputStarRating',
  components: {
    IconStar
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      default: 1
    },
    max: {
      type: [String, Number],
      default: 3
    }
  },
  methods: {
    classes (n) {
      const classes = [
        'left-0',
        'top-0',
        'h-full',
        'cursor-pointer'
      ]
      if (n === this.max) {
        classes.push('static')
      } else {
        classes.push('absolute')
      }
      return classes.join(' ')
    },
    onChange (value) {
      this.$emit('change', value)
    }
  }
}
</script>
