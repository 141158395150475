<template>
  <div class="modal" :class="{show}">
    <aside class="dialog mx-4 w-full max-w-sm">
      <header>エラーが発生しました</header>
      <section>
        <ul class="text-red-500">
          <li v-for="(msg, idx) in errors" :key="idx">
            <span>{{msg}}</span>
          </li>
        </ul>
      </section>
      <footer>
        <button type="button" class="button button-text button-blue" @click="back">戻る</button>
        <button type="button" class="ml-2 button button-text button-blue" @click="reload">再読み込み</button>
      </footer>
    </aside>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  computed: {
    show () {
      return this.errors.length > 0
    }
  },
  watch: {
    show (v) {
      const bodyclass = document.body.classList
      if (v) {
        bodyclass.add('show-modal')
      } else {
        bodyclass.remove('show-modal')
      }
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    reload () {
      location.reload(false)
    }
  }
}
</script>
