import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import pagestate from './pagestate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    pagestate
  },

  mutations: {
    init (state) {
      this.commit('userFromStorage')
    }
  }
})

store.commit('init')

export default store
