//
// Debounce
//
export default class Debounce {
  constructor (handler, msec) {
    this._h = handler
    this._tid = 0
    this._msec = msec
  }

  on () {
    clearTimeout(this._tid)

    this._tid = setTimeout(() => {
      this._tid = 0
      this._h()
    }, this._msec)
  }
}
